<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Subcontratistas</h2>
    <button
      class="
        rounded
        border border-primary
        bg-primary
        py-2
        px-4
        text-white
        font-medium
        flex
      "
      @click="onOpenDrawerSubcontractor({ option: 'create' })"
    >
      Crear subcontratista
    </button>
  </div>

  <div class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead class="">
          <tr>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">
              Nombre
            </th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <tr
            v-for="(subcontractor, ind) in getSubcontractors"
            :key="ind"
            class="whitespace-nowrap"
          >
            <td
              class="text-left text-secondary font-light px-6 py-4 text-sm pl-0"
            >
              {{ subcontractor.name }}
            </td>
            <td class="px-6 py-4 pr-0">
              <div class="flex md:justify-end">
                <a
                  class="
                    text-primary
                    font-medium
                    cursor-pointer
                    text-right
                    hover:bg-blue-50
                    px-2
                  "
                  @click="
                    onOpenDrawerSubcontractor({
                      action: 'update',
                      subcontractor: subcontractor,
                    })
                  "
                >
                  <p>Editar</p>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer
    :open="drawerSubcontractor"
    @update:open="drawerSubcontractor = $event"
  >
    <SubcontractorForm
      v-if="drawerSubcontractor"
      :action="subcontractorFormAction"
      :subcontractor-selected="subcontractorSelected"
      @onComplete="drawerSubcontractor = false"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import SubcontractorForm from "./forms/Subcontractor";

const store = useStore();

const drawerSubcontractor = ref(false);
const subcontractorFormAction = ref("create");
const subcontractorSelected = ref(null);

const getSubcontractors = computed(() => store.getters.getSubcontractors);

const init = async () => {
  await store.dispatch("getAllSubcontractors");
};

init();

const onOpenDrawerSubcontractor = ({ action, subcontractor = null }) => {
  subcontractorSelected.value = { ...subcontractor };
  drawerSubcontractor.value = true;
  subcontractorFormAction.value = action;
};
</script>
